<template>
  <div>
    <div class="greeting">Hello {{ name }}{{ exclamationMarks }}</div>
    <button @click="decrement">-</button>
    <button @click="increment">+</button>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  props: ["name", "initialEnthusiasm"],
  data() {
    return {
      enthusiasm: 0,
    };
  },
  methods: {
    increment() {
      this.enthusiasm++;
    },
    decrement() {
      if (this.enthusiasm > 1) {
        this.enthusiasm--;
      }
    },
  },
  computed: {
    exclamationMarks() {
      return this.enthusiasm + "-------";
    },
  },
//   watch: {
//     enthusiasm() {
//       console.log(this.enthusiasm);
//     },
//   },
});
</script>